import * as React from 'react';
import { IConfig } from './types/Config.Type';
import { ILoaderProps } from './map/layer/ILoaderProps';
import { OnshoreAquaculture } from './map/layers/civ/OnshoreAquaculture';

const Config: IConfig = {
  map: {
    mapKey: "pk.eyJ1IjoibG9uZ2xpbmVlbnZpcm9ubWVudCIsImEiOiJjbGF0cHF1ZWUwM2l0M3FwcDcyN3B1YXpmIn0.snFi9yTPEZ5lfQxE3h3Epg",
    greyStyle: null, // "mapbox://styles/longlineenvironment/clatpsjsl003r15okdwsdclmi",
    satelliteStyle: "mapbox://styles/longlineenvironment/clnkins3a001s01qw9k3efaj5/draft",
    longitude: -5.51,
    latitude: 7.643,  
    zoom: 6, 
    minZoom: 0,
    maxZoom: 14,
    bearing: 0,
    pitch: 0,
    max_bounds: [
      [-15.8,   0.69],    // Southwest (lng/lat)
      [ 4.66,  15.14]     // Northeast (lng/lat)
    ]
  },
  UI: {
    height: 75,
    before_layer: 'waterway-label',
    large_screen: 900,
    load_delay_base: 0,
    load_delay_random: 0,
  },

  legend_caption: "Suitability",
  suitability: [
    { name: 'Spatial constraints', color: '#aaaaaa', hidden: true },
    { name: 'Not suitable', color: '#dc1010' },
    { name: 'Moderately suitable', color: '#ff6d01' },
    { name: 'Suitable', color: '#128800' },
    { name: 'Highly suitable', color: '#0cf600' },
  ],  

  datasets: [
    {
      key: 'onshore_aquaculture',
      ids: [ 'onshore_aquaculture' ],
      title: 'Onshore aquaculture suitability',
      description: 'Suitability for pond aquaculture (land)',
      group: 'aquaculture',
      active: true,
      renderer: (p: ILoaderProps) => <OnshoreAquaculture {...p}/>
    }
  ],
  datagroups: [
    {
      key: 'aquaculture',
      title: 'Onshore aquaculture',
      description: 'Suitability for onshore aquaculture',
      exclusive: true,
    }
  ],
  dictionary: [
    {
      key: 'suitability',
      text: 'Suitability',
      type: 'suitability'
    }, {
      key: 'ADM1_EN',
      text: 'Province'
    }, {
      key: 'area_km2',
      text: 'Area',
      unit: <>km&sup2;</>,
      type: 'number'
    },
  ]
}

export { Config }
